import { Checkbox, Table, Avatar } from 'lux/components';
import { Box, Stack } from '@mui/material';

import { formatRoleName } from 'utils/stringUtils';
import { formatDate } from 'utils/dateUtils';
import { Translation } from 'ui/translation/Translation';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';

import { PlanAssignmentExtensionTableProps } from './PlanAssignmentExtensionTable.types';
import { PlanAssignmentExtensionTableHead } from './planAssignmentExtensionTableHead/PlanAssignmentExtensionTableHead';
import * as styles from './PlanAssignmentExtensionTable.styles';
import { PlanAssignmentExtensionTableCell } from './planAssignmentExtensionTableCell/PlanAssignmentExtensionTableCell';

export const PlanAssignmentExtensionTable = ({
  assignments,
  extensionDate,
  onChangeAssignment,
  onChangeAllAssignments,
  selectedAssignmentIds,
}: PlanAssignmentExtensionTableProps) =>
  assignments.length > 0 ? (
    <Table.Table>
      <PlanAssignmentExtensionTableHead
        onChangeAllAssignments={onChangeAllAssignments}
        selectedAssignmentIds={selectedAssignmentIds}
      />
      <Table.Body>
        {assignments.map((assignment) => (
          <Table.Row
            key={assignment.id}
            hover
            sx={styles.tableRow(selectedAssignmentIds[assignment.id])}
            onClick={() => onChangeAssignment(assignment.id, !selectedAssignmentIds[assignment.id])}
          >
            <Table.Cell padding="checkbox">
              <Checkbox
                checked={selectedAssignmentIds[assignment.id]}
                onChange={(_, checked) => onChangeAssignment(assignment.id, checked)}
              />
            </Table.Cell>
            <PlanAssignmentExtensionTableCell isSelected={selectedAssignmentIds[assignment.id]}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Avatar
                    size="sm"
                    alt={`${assignment.person.firstName} ${assignment.person.lastName}`}
                    image={assignment.person.picture}
                  />
                  {assignment.person.firstName} {assignment.person.lastName}
                </Stack>
                <EmojiBadges
                  isLeader={assignment.isLeader}
                  employmentType={assignment.person.employmentType}
                  proposalCount={assignment.person.proposalCount}
                  employedTo={assignment.person.employedTo}
                  absences={assignment.person.absences}
                  assignmentType={assignment.type}
                  replacedAssignment={
                    assignment.replacedAssignment
                      ? `${assignment.replacedAssignment.person.firstName} ${assignment.replacedAssignment.person.lastName}`
                      : undefined
                  }
                />
              </Stack>
            </PlanAssignmentExtensionTableCell>
            <PlanAssignmentExtensionTableCell isSelected={selectedAssignmentIds[assignment.id]} width={250}>
              {formatRoleName(assignment.role, assignment.seniority)}
            </PlanAssignmentExtensionTableCell>
            <PlanAssignmentExtensionTableCell isSelected={selectedAssignmentIds[assignment.id]} width={200}>
              {assignment.startDate}
            </PlanAssignmentExtensionTableCell>
            <PlanAssignmentExtensionTableCell isSelected={selectedAssignmentIds[assignment.id]} width={200}>
              {extensionDate && selectedAssignmentIds[assignment.id] ? formatDate(extensionDate) : '-'}
            </PlanAssignmentExtensionTableCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Table>
  ) : (
    <Box sx={styles.noAssignmentsWrapper}>
      <Translation id="projectDetails.planProjectExtensionModal.noAssignments" />
    </Box>
  );
