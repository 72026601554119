import { Absence } from 'api/actions/getPersonAbsences/getPersonAbsences.types';
import { AbsenceType } from '../../api/types/AbsenceType.enum';

export type MappedAbsences = {
  bankHolidays: Absence[];
  daysOff: Absence[];
};

export const useMappedAbsences = (absences: Absence[] | undefined): MappedAbsences | undefined =>
  absences?.reduce<MappedAbsences>(
    (mappedAbsences, day) => {
      if (day.type === AbsenceType.holiday) {
        mappedAbsences.daysOff.push(day);
      } else {
        mappedAbsences.bankHolidays.push(day);
      }

      return mappedAbsences;
    },
    {
      bankHolidays: [],
      daysOff: [],
    },
  );
