import { Typography } from 'lux/components';

import { roundNumber } from 'utils/roundNumber';
import { TableCell, TableRow } from '../../../tableComponents/tableComponents';
import { HoursPerDayCell } from '../../hoursPerDayCell/HoursPerDayCell';
import { HoursSum } from '../../hoursSum/HoursSum';

import * as styles from './TeamMemberHoursDetailsRow.styles';
import { AssignmentDetailsRowProps } from './TeamMemberHoursDetails.types';

export const AssignmentHoursDetailsRow = ({
  assignmentHours,
  employeeId,
  hourType,
  title,
  rateToDisplay,
  isRootSelected,
}: AssignmentDetailsRowProps) => {
  const hoursSum = roundNumber(
    assignmentHours.reduce((sum, memberHours) => {
      const regularRate = memberHours.hours.find(
        (hour) => hour.type === hourType && hour.ratePercentage === rateToDisplay,
      );

      if (regularRate) {
        return sum + regularRate.amount;
      }

      return sum;
    }, 0),
    2,
  );

  return (
    <TableRow>
      <TableCell sx={styles.dimmedCell}>
        <Typography>{title}</Typography>
        <HoursSum isDimmed>{hoursSum}</HoursSum>
      </TableCell>
      {assignmentHours.map((assignmentHour, idx) => (
        <HoursPerDayCell
          dayNumber={idx + 1}
          key={idx}
          assignmentHour={assignmentHour}
          employeeId={employeeId}
          hoursTypeToDisplay={hourType}
          rateToDisplay={rateToDisplay}
          isRootSelected={isRootSelected}
          isDetailsRow={true}
        />
      ))}
    </TableRow>
  );
};
