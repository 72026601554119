import { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Accordion, Badge, Button, Tabs, Tooltip } from 'lux/components';
import { DeleteIcon, EditIcon, ShowIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { AppMessages } from 'i18n/messages';
import { addDaysToDate, parseDate } from 'utils/dateUtils';
import { Translation } from 'ui/translation/Translation';

import { RateCardItems } from './rateCardItems/RateCardItems';
import { UpdateRateCardModal } from './updateRateCardModal/UpdateRateCardModal';
import { RemoveFutureRateCardModal } from './removeFutureRateCardModal/RemoveFutureRateCardModal';
import { RatesHistoryModal } from './ratesHistoryModal/RatesHistoryModal';
import { extractNeededRoles, getMissingNeededRatesCount } from './RateCardDetails.utils';
import * as styles from './RateCardDetails.styles';
import type { RateCardDetailsProps } from './RateCardDetails.types';

export const RateCardDetails = ({ disabled }: RateCardDetailsProps) => {
  const [expanded, setExpanded] = useState(true);
  const { formatMessage } = useLocale();
  const { projectDetails } = useProjectDetails();

  const { currentRateCard, futureRateCard, previousRateCards } = projectDetails;
  const tabIndex = Object.freeze({
    previous: 0,
    current: !!previousRateCards.length ? 1 : 0,
    future: !!previousRateCards.length ? 2 : 1,
  });

  const neededRoles = extractNeededRoles(projectDetails.needs);
  const missingNeededRatesCount = getMissingNeededRatesCount(neededRoles, currentRateCard);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(tabIndex.current);

  const {
    isOpen: isSeeFullHistoryOpen,
    setOpen: setSeeFullHistoryOpen,
    setClose: setSeeFullHistoryClose,
  } = useDialog();
  const {
    isOpen: isUpdateRateCardOpen,
    setOpen: setUpdateRateCardOpen,
    setClose: setUpdateRateCardClose,
  } = useDialog();
  const {
    isOpen: isUpdateFutureRateCardOpen,
    setOpen: setUpdateFutureRateCardOpen,
    setClose: setUpdateFutureRateCardClose,
  } = useDialog();
  const {
    isOpen: isRemoveFutureRateCardOpen,
    setOpen: setRemoveFutureRateCardOpen,
    setClose: setRemoveFutureRateCardClose,
  } = useDialog();

  const handleTabIndexChange = (e: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  return (
    <Accordion
      summary={
        <>
          <Translation id="projectDetails.accordions.details.rateCard" />
          {!!missingNeededRatesCount && (
            <Badge sx={styles.titleBadge} isStatic color="orange" badgeContent={missingNeededRatesCount} />
          )}
        </>
      }
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <Accordion.Details sx={styles.accordionDetails(!previousRateCards.length && !futureRateCard)}>
        <Tabs>
          <Tabs.TabList value={activeTabIndex} onChange={handleTabIndexChange} variant="fullWidth">
            {!!previousRateCards.length && (
              <Tabs.Tab label={<Translation id="projectDetails.accordions.details.rateCard.tab.previous" />} />
            )}
            <Tabs.Tab label={<Translation id="projectDetails.accordions.details.rateCard.tab.current" />} />
            {!!futureRateCard && (
              <Tabs.Tab label={<Translation id="projectDetails.accordions.details.rateCard.tab.future" />} />
            )}
          </Tabs.TabList>
          {!!previousRateCards.length && (
            <Tabs.TabPanel value={activeTabIndex} index={tabIndex.previous}>
              <RateCardItems rateCard={previousRateCards[0]}>
                <Button
                  variant="outlined"
                  startIcon={<ShowIcon />}
                  size="small"
                  onClick={setSeeFullHistoryOpen}
                  fullWidth
                >
                  <Translation id="projectDetails.accordions.details.rateCard.tab.previous.seeFullHistory" />
                </Button>
              </RateCardItems>
            </Tabs.TabPanel>
          )}
          <Tabs.TabPanel value={activeTabIndex} index={tabIndex.current}>
            <RateCardItems rateCard={currentRateCard} neededRoles={neededRoles}>
              <Tooltip
                title={
                  !!futureRateCard ? (
                    <Translation id="projectDetails.accordions.details.rateCard.tab.currentDisabled.tooltip" />
                  ) : (
                    ''
                  )
                }
                placement="bottom"
                arrow
              >
                <Box>
                  <Button
                    disabled={disabled || !!futureRateCard}
                    variant="outlined"
                    startIcon={<EditIcon />}
                    size="small"
                    onClick={setUpdateRateCardOpen}
                    fullWidth
                  >
                    <Translation id="button.updateRateCard" />
                  </Button>
                </Box>
              </Tooltip>
            </RateCardItems>
          </Tabs.TabPanel>
          {!!futureRateCard && (
            <Tabs.TabPanel value={activeTabIndex} index={tabIndex.future}>
              <RateCardItems rateCard={futureRateCard} neededRoles={neededRoles}>
                <Stack columnGap={0.5} direction="row" justifyContent="space-between" alignItems="flex-start">
                  <Button
                    disabled={disabled}
                    variant="outlined"
                    size="small"
                    onClick={setUpdateFutureRateCardOpen}
                    fullWidth
                  >
                    <Translation id="projectDetails.accordions.details.rateCard.tab.future.editFutureRates" />
                  </Button>
                  <Button
                    disabled={disabled}
                    color="error"
                    variant="text"
                    startIcon={<DeleteIcon />}
                    size="small"
                    onClick={setRemoveFutureRateCardOpen}
                    fullWidth
                  >
                    <Translation id="projectDetails.accordions.details.rateCard.tab.future.removeFuture" />
                  </Button>
                </Stack>
              </RateCardItems>
            </Tabs.TabPanel>
          )}
        </Tabs>
      </Accordion.Details>
      <RatesHistoryModal
        rateCards={[projectDetails.currentRateCard, ...projectDetails.previousRateCards]}
        open={isSeeFullHistoryOpen}
        onClose={setSeeFullHistoryClose}
      />
      <UpdateRateCardModal
        open={isUpdateRateCardOpen}
        onClose={setUpdateRateCardClose}
        title={formatMessage({
          id: AppMessages['projectDetails.accordions.details.rateCard.updateRateCard.title'],
        })}
        updatedRateCard={currentRateCard}
        minDate={parseDate(currentRateCard.appliesFrom)}
        key="UpdateRateCardModal"
        currentRateCard={currentRateCard}
      />
      {!!futureRateCard && (
        <UpdateRateCardModal
          open={isUpdateFutureRateCardOpen}
          onClose={setUpdateFutureRateCardClose}
          title={formatMessage({
            id: AppMessages['projectDetails.accordions.details.rateCard.updateFutureRateCard.title'],
          })}
          updatedRateCard={futureRateCard}
          minDate={addDaysToDate(parseDate(currentRateCard.appliesFrom), 1)}
          key="UpdateFutureRateCardModal"
          currentRateCard={currentRateCard}
        />
      )}
      {!!futureRateCard && (
        <RemoveFutureRateCardModal
          open={isRemoveFutureRateCardOpen}
          onSuccess={() => setActiveTabIndex(tabIndex.current)}
          onClose={setRemoveFutureRateCardClose}
          projectId={projectDetails.id}
        />
      )}
    </Accordion>
  );
};
