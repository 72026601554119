import { useQueryClient } from '@tanstack/react-query';

import { requestNeedMutation } from 'api/actions/requestNeed/requestNeedActions';
import { dictionariesKeys, needsKeys, projectsKeys } from 'utils/queryKeys';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';

export const useRequestNeed = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutationDomainError(['requestNeedMutation'], requestNeedMutation(projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(projectsKeys.singleProjectNeeds(projectId));
      queryClient.invalidateQueries(dictionariesKeys.skillsList);
      queryClient.invalidateQueries(dictionariesKeys.specializationsList);
      queryClient.invalidateQueries(needsKeys.needsList);
    },
  });
};
