import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { PeopleFiltersContext } from '../peopleFiltersContext/PeopleFiltersContext';
import { PeopleSortingField } from '../peopleFiltersContext/PeopleFiltersContext.types';
import { useFilters } from 'hooks/useFilters/useFilters';
import { generateSortFilter } from 'utils/filters/filters';
import { filterEmptyQueryParams, stringifyQueryParams } from 'api/utils/queryString';
import { useOnRedirectHandler } from 'hooks/useOnRedirectHandler/useOnRedirectHandler';
import type { PeopleFilters } from '../peopleFiltersContext/PeopleFiltersContext.types';

import type { PeopleFiltersContextControllerProps } from './PeopleFiltersContextController.types';

export const peopleSortingOptions = generateSortFilter(PeopleSortingField);

const defaultPeopleFilters: PeopleFilters = {
  search: '',
  sort: peopleSortingOptions.nameASC,
  seniorities: [],
  departments: [],
  status: [],
  fte: '',
  rateMin: '',
  rateMax: '',
  skills: [],
  availabilityFrom: '',
  availabilityTo: '',
  employmentType: [],
  includeUnemployed: 'false',
  nonDevRoles: 'false',
};
export const PeopleFiltersContextController = ({
  children,
  withParams,
  peopleFilters,
  storageKey,
  storageType,
}: PeopleFiltersContextControllerProps) => {
  const [, setParamsFilters] = useSearchParams();

  const activePeopleFilters = {
    ...defaultPeopleFilters,
    ...peopleFilters,
  };

  const contextValues = useFilters<PeopleFilters>(activePeopleFilters, { withParams, storageKey, storageType });

  useEffect(() => {
    if (withParams) {
      setParamsFilters(stringifyQueryParams(filterEmptyQueryParams(contextValues.filters, defaultPeopleFilters)), {
        replace: true,
      });
    }
  }, []);

  useOnRedirectHandler(() => {
    contextValues.setStorageFiltersState({
      search: undefined,
    });
  });

  return <PeopleFiltersContext.Provider value={contextValues}>{children}</PeopleFiltersContext.Provider>;
};
