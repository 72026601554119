import { Stack } from '@mui/material';
import { Typography } from 'lux/components';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { createIntervalsFromAbsences } from 'utils/createIntervalsFromAbsences';
import { formatDate } from 'utils/dateUtils';

import { AbsencesWarningProps } from './AbsencesWarning.types';

export const AbsencesWarning = ({ absences, employeeId }: AbsencesWarningProps) => {
  const { t } = useLocale();

  const sortedAbsenceIntervals = createIntervalsFromAbsences(absences).sort(
    (a, b) => a.from.valueOf() - b.from.valueOf(),
  );

  return (
    <InfoBox
      title={t(AppMessages['assignOrProposeAnyway.warnings.absences.title'])}
      variant="warning"
      actionLabel={t(AppMessages['assignOrProposeAnyway.warnings.absences.action'])}
      onAction={() => window.open(`${window.location.origin}/people/${employeeId}`, '_blank', 'noopener,noreferrer')}
    >
      {sortedAbsenceIntervals.map(({ from, to, durationInDays }) => (
        <Stack gap={0.5} direction="row" key={from.getTime()}>
          <Typography variant="body2">
            {formatDate(from)} - {formatDate(to)}
          </Typography>

          <Typography variant="body2" fontWeight={500}>
            {t(AppMessages['assignOrProposeAnyway.warnings.absences.daysCount'], {
              daysCount: durationInDays,
            })}
          </Typography>
        </Stack>
      ))}
    </InfoBox>
  );
};
