import { Button, Tooltip } from 'lux/components';
import { AddUserIcon, CloseIcon } from 'lux/icons';
import { Box } from '@mui/material';

import { Translation } from 'ui/translation/Translation';

import type { ApprovedProposalButtonsProps } from './ApprovedProposalButtons.types';

export const ApprovedProposalButtons = (props: ApprovedProposalButtonsProps) => {
  const { assignDisabled, onAssign, onRemove } = props;

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          onClick={onRemove}
          color="error"
          size="small"
          data-cy="pending-proposal_remove-btn"
          startIcon={<CloseIcon />}
        >
          <Translation id="button.remove" />
        </Button>
      </Box>

      <Tooltip
        arrow
        placement="right"
        title={assignDisabled.value && <Translation id={`card.need.approved.${assignDisabled.reason}`} />}
      >
        <span>
          <Button
            variant="contained"
            disabled={assignDisabled.value}
            onClick={onAssign}
            size="small"
            data-cy="approved-proposal_btn"
            startIcon={<AddUserIcon />}
          >
            <Translation id="button.assign" />
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
