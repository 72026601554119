import Joi from 'joi';

import { addDaysToDate, getCurrentDayStartDate, parseDate } from 'utils/dateUtils';
import { AppMessages } from 'i18n/messages';
import { intl } from 'context/locale/intl/Intl';

export const closeProjectSchema = (projectStartDate: string) => {
  const { formatMessage } = intl.currentIntl;

  return Joi.object().keys({
    endDate: Joi.date()
      .max(getCurrentDayStartDate())
      .min(addDaysToDate(parseDate(projectStartDate), 1))
      .required()
      .messages({
        'date.base': formatMessage(
          {
            id: AppMessages['validation.date'],
          },
          { label: formatMessage({ id: AppMessages['projectDetails.closeProjectModal.endDate.label'] }) },
        ),
        'date.min': formatMessage(
          { id: AppMessages['projectDetails.closeProjectModal.validation.endDate.min'] },
          { startDate: projectStartDate },
        ),
        'date.max': formatMessage({ id: AppMessages['projectDetails.closeProjectModal.validation.endDate.max'] }),
      }),
    closingReason: Joi.string()
      .required()
      .messages({
        'string.empty': formatMessage(
          { id: AppMessages['validation.empty'] },
          { label: formatMessage({ id: AppMessages['projectDetails.closeProjectModal.closingReason.label'] }) },
        ),
      }),
  });
};
