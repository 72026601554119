import { Button } from 'lux/components';
import { CheckIcon, CloseIcon } from 'lux/icons';

import { useNeedModal } from 'hooks/useNeedModal/useNeedModal';
import { Translation } from 'ui/translation/Translation';

import type { PendingProposalButtonsProps } from './PendingProposalButtons.types';

export const PendingProposalButtons = (props: PendingProposalButtonsProps) => {
  const { onReject, onAccept, onRemove } = props;
  const { isApprovePersonLoading } = useNeedModal();

  return (
    <>
      <Button
        variant="outlined"
        onClick={onRemove}
        color="error"
        size="small"
        data-cy="pending-proposal_remove-btn"
        startIcon={<CloseIcon />}
      >
        <Translation id="button.remove" />
      </Button>

      <Button
        variant="outlined"
        onClick={onReject}
        color="error"
        size="small"
        data-cy="pending-proposal_reject-btn"
        startIcon={<CloseIcon />}
      >
        <Translation id="button.reject" />
      </Button>

      <Button
        variant="contained"
        onClick={onAccept}
        size="small"
        disabled={isApprovePersonLoading}
        startIcon={<CheckIcon />}
        data-cy="pending-proposal_accept-btn"
      >
        <Translation id="button.accept" />
      </Button>
    </>
  );
};
