import { MouseEventHandler, useEffect, useRef, useState } from 'react';

import { SxProps } from '@mui/material';
import { Tooltip } from 'lux/components';

import { Loader } from 'ui/loader/Loader';
import { useProjectTimesheet } from 'hooks/useProjectTimesheet/useProjectTimesheet';
import { useProjectTimesheetData } from 'hooks/useProjectTimesheetData/useProjectTimesheetData';
import { SelectedTimesheetHoursCell } from 'context/projectTimesheet/projectTimesheetContext/ProjectTimesheetContext.types';
import { areDatesInTheSameDay, formatDate, isWeekendDate, parseDate } from 'utils/dateUtils';
import { TableCell } from '../../tableComponents/tableComponents';
import { useUpdateBillingDay } from 'hooks/useUpdateBillingDay/useUpdateBillingDay';
import { roundHours } from 'utils/roundHours';
import { useGetIsFirstDayOfProject } from 'hooks/useGetIsFirstDayOfProject/useGetIsFirstDayOfProject';
import { isAbsentHourType } from 'utils/is-absent-hour-type';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HourType } from 'api/types/HourType.enum';

import * as styles from './HoursPerDayCell.styles';
import { GetDisplayValueProps, HoursPerDayCellProps } from './HoursPerDayCell.types';
import { PopoverSaveHandler } from './hoursPopover/HoursPopover.types';
import { HoursPopover } from './hoursPopover/HoursPopover';

const getDisplayValue = (params: GetDisplayValueProps): string => {
  const { sumOfHours, isAbsent, isWeekend } = params;

  if (sumOfHours > 0) {
    return roundHours(sumOfHours);
  }

  return (isAbsent && !isWeekend) || sumOfHours === 0 ? '-' : '';
};

export const HoursPerDayCell = ({
  assignmentHour,
  employeeId,
  hoursTypeToDisplay = HourType.total,
  rateToDisplay,
  dayNumber,
  isRootSelected,
  setIsRootSelected,
  isDetailsRow,
}: HoursPerDayCellProps) => {
  const { formatMessage } = useLocale();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { setSelectedTimesheetHoursCell, selectedTimesheetHoursCell } = useProjectTimesheet();
  const {
    billingCycleId,
    isFetchingTimesheetData,
    isTimesheetEditBlocked,
    showTimesheetSnapshot,
    snapshotCreateDate,
    isReport,
  } = useProjectTimesheetData();
  const getIsFirstDayOfProject = useGetIsFirstDayOfProject();
  const { isProjectStartDayMarker, isBeforeProjectStart, isAfterProjectEnd } = getIsFirstDayOfProject(
    Number(dayNumber),
  );

  const { mutate: updateBillingDay, isLoading } = useUpdateBillingDay();
  const showLoader = useRef(false);
  const { dataMismatch, edited: valueWasEdited } = assignmentHour;
  const isDataMismatch = Boolean(dataMismatch);

  const isBillingDayData = !!assignmentHour.hours.length;

  const clickedTimesheetHoursCell: SelectedTimesheetHoursCell = {
    ...assignmentHour,
    employeeId,
  };

  const mismatchValue = showTimesheetSnapshot ? dataMismatch?.hours?.current : dataMismatch?.hours?.snapshot;

  const isOutsideProjectDates = isBeforeProjectStart || isAfterProjectEnd;

  const getTooltipTitle = () => {
    if (isDataMismatch) {
      return 'projectDetails.timesheet.tooltip.data.mismatch';
    }

    if (isTimesheetEditBlocked) {
      return 'projectDetails.timesheet.confirmedInvoice.title';
    }

    if (isOutsideProjectDates) {
      return 'projectDetails.timesheet.dayOutsideProjectDates.title';
    }

    return 'projectDetails.timesheet.dayOutsidePersonAssignment.title';
  };

  const isSelected = !!(
    (isRootSelected || !!anchorEl) &&
    selectedTimesheetHoursCell &&
    selectedTimesheetHoursCell.employeeId === employeeId &&
    areDatesInTheSameDay(parseDate(selectedTimesheetHoursCell.date), parseDate(assignmentHour.date))
  );
  const isWeekend = isWeekendDate(parseDate(assignmentHour.date));
  const isAbsent = assignmentHour.hours.every((hour) => isAbsentHourType(hour.type));

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (isOutsideProjectDates || !isBillingDayData || isTimesheetEditBlocked || showTimesheetSnapshot || isReport) {
      return;
    }

    const preventHandleClick = isOutsideProjectDates || !isBillingDayData;

    if (preventHandleClick) return;

    setAnchorEl(event.currentTarget);
    setSelectedTimesheetHoursCell(clickedTimesheetHoursCell);
    setIsRootSelected?.(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedTimesheetHoursCell(null);
    setIsRootSelected?.(false);
  };

  const handleSavePopover: PopoverSaveHandler = (hours) => {
    showLoader.current = true;

    updateBillingDay({
      employeeId,
      billingCycleId,
      billingDay: {
        date: formatDate(parseDate(clickedTimesheetHoursCell.date)),
        hours,
      },
    });
    setAnchorEl(null);
    setSelectedTimesheetHoursCell(null);
    setIsRootSelected?.(false);
  };

  const hoursByHourType =
    hoursTypeToDisplay === HourType.total
      ? assignmentHour
      : {
          ...assignmentHour,
          hours: assignmentHour.hours.filter(
            (hour) => hour.type === hoursTypeToDisplay && hour.ratePercentage === rateToDisplay,
          ),
        };

  const sumOfHours = hoursByHourType.hours.reduce((sum, billingHours) => sum + billingHours.amount, 0);
  const sx: SxProps = styles.cell({
    isProjectStartDayMarker,
    valueWasEdited,
    isReport,
    isWeekend,
  });
  const displayValue =
    isOutsideProjectDates || !isBillingDayData ? '' : getDisplayValue({ sumOfHours, isAbsent, isWeekend });

  const cellProps = {
    isWeekend,
    isClickable:
      !isOutsideProjectDates && isBillingDayData && !isTimesheetEditBlocked && !showTimesheetSnapshot && !isReport,
    isOutsideProjectDates: isOutsideProjectDates || !isBillingDayData,
    isAbsent,
    isSelected,
    isDataMismatch,
    isReport,
    showTimesheetSnapshot,
    isDetailsRow,
    onClick: handleClick,
    sx,
  };

  useEffect(() => {
    if (!isFetchingTimesheetData) {
      showLoader.current = false;
    }
  }, [isFetchingTimesheetData]);

  const cellValue =
    showLoader.current && (isLoading || isFetchingTimesheetData) ? <Loader sx={styles.loader} /> : displayValue;

  const getCellClassNames = () => {
    let className;

    if (isWeekend || isAbsent) {
      className = 'Absence';
    }

    if (isWeekend) {
      className += ' Weekend';
    }

    return className;
  };

  return (
    <>
      {isOutsideProjectDates || !isBillingDayData || isTimesheetEditBlocked || isDataMismatch ? (
        <Tooltip
          placement="top"
          arrow
          title={formatMessage(
            {
              id: AppMessages[getTooltipTitle()],
            },
            {
              date: snapshotCreateDate,
              value: mismatchValue,
            },
          )}
          PopperProps={{ sx: styles.popper }}
        >
          <TableCell {...cellProps} className={getCellClassNames()}>
            {cellValue}
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell {...cellProps} className={getCellClassNames()}>
          {cellValue}
        </TableCell>
      )}
      <HoursPopover
        hours={clickedTimesheetHoursCell.hours}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        onSave={handleSavePopover}
        hoursTypeToDisplay={hoursTypeToDisplay}
      />
    </>
  );
};
