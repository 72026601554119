import { Stack } from '@mui/material';
import { DataDisplay, Accordion, Typography } from 'lux/components';

import { AbsenceType } from 'api/types/AbsenceType.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { usePersonAbsences } from 'hooks/usePersonAbsences/usePersonAbsences';
import { usePersonData } from 'hooks/usePersonData/usePersonData';
import { AppMessages } from 'i18n/messages';
import { Loader } from 'ui/loader/Loader';
import { createIntervalsFromAbsences } from 'utils/createIntervalsFromAbsences';
import { formatDate } from 'utils/dateUtils';

export const PersonAbsences = () => {
  const { person } = usePersonData();
  const { data, isLoading } = usePersonAbsences({
    personId: String(person.employeeId),
    types: [AbsenceType.holiday],
  });
  const { formatMessage } = useLocale();

  if (isLoading) {
    return <Loader fullHeight={false} />;
  }

  if (!data?.daysOff) {
    return null;
  }

  const absenceIntervals = createIntervalsFromAbsences(data.daysOff);

  return (
    <Accordion defaultExpanded summary={formatMessage({ id: AppMessages['personDetails.accordion.absences'] })}>
      <Accordion.Details>
        <Stack spacing={3}>
          {absenceIntervals.map(({ type, from, to, durationInDays }) => (
            <Stack spacing={0.5} key={from.getTime()}>
              <DataDisplay
                header={`${type} 
                ${formatMessage(
                  { id: AppMessages['personDetails.accordion.absences.daysCount'] },
                  {
                    daysCount: durationInDays,
                  },
                )}`}
              >
                <Typography variant="caption">
                  {formatDate(from)} - {formatDate(to)}
                </Typography>
              </DataDisplay>
            </Stack>
          ))}
        </Stack>
      </Accordion.Details>
    </Accordion>
  );
};
