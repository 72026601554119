import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { Badge, Avatar, Typography, Grid, DataDisplay } from 'lux/components';
import { ChevronDownIcon, NoteIcon } from 'lux/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { fteToHumanValue } from 'app/need/utils';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { composeStyles } from 'utils/composeStyles';
import { EmojiBadges } from 'ui/emojiBadges/EmojiBadges';
import { PersonTooltip } from 'shared/people/personTooltip/PersonTooltip';

import { AssignmentAccordionProps, AssignmentData } from './AssignmentAccordion.types';
import * as styles from './AssignmentAccordion.styles';

export const AssignmentAccordion = ({
  onOpenAssignment,
  expandedAssignment,
  assignment,
  projectCurrency,
}: AssignmentAccordionProps) => {
  const { formatMessage } = useLocale();

  const {
    startDate,
    endDate,
    fte,
    effectiveRateModifier,
    linkedRejection,
    id,
    person,
    isLeader,
    role,
    status,
    type,
    replacedAssignment,
  } = assignment;
  const isExpanded = expandedAssignment === id;
  const isPersonLeft = !linkedRejection && status === AssignmentStatus.left;

  const personRoutePath = `/people/${person.employeeId}`;

  const assignmentData: AssignmentData[] = [
    {
      labelKey: 'projectDetails.assignmentHistory.assignmentAccordion.inProjectFrom',
      value: startDate,
    },
    {
      labelKey: 'projectDetails.assignmentHistory.assignmentAccordion.inProjectTo',
      value: endDate || '-',
    },
    {
      labelKey: 'projectDetails.assignmentHistory.assignmentAccordion.fte',
      value: formatMessage({ id: AppMessages[fteToHumanValue(fte)] }, { fte: fte }),
    },
    {
      labelKey: 'projectDetails.assignmentHistory.assignmentAccordion.latestRate',
      value: effectiveRateModifier ? `${effectiveRateModifier} ${projectCurrency}` : '-',
    },
  ];

  if (linkedRejection) {
    assignmentData.push({
      labelKey: 'projectDetails.assignmentHistory.assignmentAccordion.rejectionReason',
      value: linkedRejection.reason,
    });
  }

  return (
    <Accordion expanded={isExpanded} onChange={onOpenAssignment} sx={styles.accordion}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon sx={styles.expandIcon} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={styles.accordionSummary}
      >
        <Box sx={composeStyles([styles.accordionHeaderWrapper, styles.headerContent])}>
          <Box sx={styles.accordionHeaderWrapper}>
            <PersonTooltip employeeId={person.employeeId}>
              <Avatar image={person.picture} sx={styles.avatar} alt={`${person.firstName} ${person.lastName}`} />
            </PersonTooltip>
            <Box sx={styles.accordionHeaderProfileDetails}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="caption">{`${person.firstName} ${person.lastName}`}</Typography>
                <EmojiBadges
                  isLeader={isLeader}
                  employmentType={person.employmentType}
                  proposalCount={person.proposalCount}
                  employedTo={person.employedTo}
                  absences={person.absences}
                  assignmentType={type}
                  replacedAssignment={
                    replacedAssignment
                      ? `${replacedAssignment.person.firstName} ${replacedAssignment.person.lastName}`
                      : undefined
                  }
                />
              </Stack>
              <Typography color="text.secondary" variant="caption">
                {role}
              </Typography>
            </Box>
          </Box>

          <Box sx={composeStyles([styles.accordionHeaderWrapper, styles.badgesContainer])}>
            {linkedRejection && (
              <Badge
                isStatic
                color="red"
                badgeContent={formatMessage({
                  id: AppMessages['projectDetails.assignmentHistory.assignmentAccordion.rejected'],
                })}
              />
            )}
            {isPersonLeft && (
              <Badge isStatic color="gray" badgeContent={formatMessage({ id: AppMessages['assignmentStatus.left'] })} />
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        <Grid container gutter={0}>
          {assignmentData.map(({ labelKey, value }) => (
            <Grid item xs={6} key={labelKey}>
              <DataDisplay header={formatMessage({ id: AppMessages[labelKey] })}>
                <Typography variant="caption">{value}</Typography>
              </DataDisplay>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box component={Link} to={personRoutePath} target="_blank" sx={styles.personLink}>
              <Button variant="outlined" size="small" startIcon={<NoteIcon />}>
                {formatMessage({ id: AppMessages['projectDetails.assignmentHistory.assignmentAccordion.buttonLabel'] })}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
