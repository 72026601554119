import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { AppRoute } from 'routing/AppRoute.enum';
import { Version } from 'ui/version/Version';
import { composeStyles } from 'utils/composeStyles';

import * as styles from './Footer.styles';

export const Footer = () => {
  const { pathname } = useLocation();
  const isOnSingleProjectPage = pathname.startsWith(AppRoute.projects + '/');

  const footerStyles = isOnSingleProjectPage
    ? composeStyles([styles.footer, styles.projectsBackgroundColor])
    : styles.footer;

  return (
    <Box sx={footerStyles}>
      <Version />
    </Box>
  );
};
