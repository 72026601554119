import { useQueryClient } from '@tanstack/react-query';

import { AppMessages } from 'i18n/messages';
import { requestInvoiceCorrection } from 'api/actions/requestInvoiceCorrection/requestInvoiceCorrection';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { getInvoicesQueryKey } from 'utils/queryKeys';

export const useInvoiceCorrection = (invoiceId: string) => {
  const queryClient = useQueryClient();

  const { formatMessage } = useLocale();
  const { invalidateBillingSummaryCache, projectId } = useProjectBillingSummaryData();

  return useMutationDomainError(['requestInvoiceCorrection'], requestInvoiceCorrection(invoiceId), {
    onSuccess: () => {
      invalidateBillingSummaryCache();
      queryClient.invalidateQueries(getInvoicesQueryKey({ projectId }));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.requestInvoiceCorrection.error'] }),
  });
};
