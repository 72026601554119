import { DataMismatch } from 'context/projectTimesheet/projectTimesheetDataContext/ProjectTimesheetDataContext.types';
import { EmployeeId } from 'api/actions/getPerson/getPerson.types';

import { InvoiceStatusEnum } from './BillingSummary.types';
import { HourType } from './HourType.enum';

export enum SelectedBillingCycleKey {
  SelectedBillingCycle = 'selectedBillingCycle',
}

export type TimesheetHours = {
  amount: number;
  type: HourType;
  ratePercentage: number;
  note?: string;
};

export type Assignment = {
  employeeId: EmployeeId;
  role: string;
  firstName: string;
  lastName: string;
  isMismatch: boolean;
  picture: string | null;
  totalHours: number;
  billingDays: [
    {
      date: string;
      hours: TimesheetHours[];
      totalHours: number;
      edited: boolean;
      dataMismatch: DataMismatch;
    },
  ];
};

export type WorkStatement = {
  id: string;
  statementNumber: string;
  startDate: string;
  endDate: string;
  totalHours: number;
  assignments: Assignment[];
  invoiceStatus?: InvoiceStatusEnum;
};

export type Timesheet = {
  billingCycleId: string;
  updatedAt: string;
  workStatements: WorkStatement[];
  username: string;
};
