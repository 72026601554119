import { Typography } from 'lux/components';

import * as styles from './Version.styles';

export const Version = () => {
  const version = process.env.REACT_APP_VERSION;
  const commitHash = process.env.REACT_APP_COMMIT_HASH;

  return (
    <Typography sx={styles.version}>
      {!version && '#'}
      {version || commitHash}
    </Typography>
  );
};
