import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { useMutation } from 'hooks/useMutation/useMutation';
import { assignmentsKeys, projectsKeys } from 'utils/queryKeys';
import { editAssignmentMutation } from 'api/actions/editAssignment/editAssignmentActions';
import { getApiError } from '../../api/utils/getApiError';

export const useEditAssignment = (projectId: string, assignmentId: string) => {
  const queryClient = useQueryClient();

  return useMutation(['editAssignment'], editAssignmentMutation(projectId, assignmentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(projectsKeys.singleProject(projectId));
      queryClient.invalidateQueries(assignmentsKeys.assignmentsList);
    },
    onError: (error) => {
      enqueueSnackbar(getApiError(error), {
        variant: 'error',
      });
    },
  });
};
