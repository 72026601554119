import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { AssignmentRemovalArguments, AssignmentRemovalResponse } from './assignmentRemoval.types';

export const assignmentRemovalMutation: (
  projectId: string,
  needId: string,
) => MutationFn<AssignmentRemovalArguments, AssignmentRemovalResponse> =
  (projectId: string, assignmentId: string) => (body) => ({
    endpoint: `/projects/${projectId}/assignments/${assignmentId}/leave`,
    method: 'PATCH',
    params: body,
  });
