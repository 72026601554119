import { Absence } from 'api/actions/getPersonAbsences/getPersonAbsences.types';

import { getNumberOfCalendarDaysBetweenDates, getEndOfDayDate, parseDate } from './dateUtils';

export type AbsenceInterval = {
  id: string;
  type: string;
  from: Date;
  to: Date;
  durationInDays: number;
};

export const createIntervalsFromAbsences = (absences: Absence[]) => {
  const sortedAbsences = absences.sort(
    (a, b) =>
      parseDate(`${a.year}-${a.month}-${a.day}`).getTime() - parseDate(`${b.year}-${b.month}-${b.day}`).getTime(),
  );

  return sortedAbsences.reduce<AbsenceInterval[]>((intervals, absence) => {
    const { id, type, year, month, day } = absence;

    const date = parseDate(`${year}-${month}-${day}`);

    const lastInterval = intervals[intervals.length - 1];

    if (!lastInterval || getNumberOfCalendarDaysBetweenDates(date, lastInterval.to) > 1) {
      return [
        ...intervals,
        {
          id,
          type,
          from: date,
          to: getEndOfDayDate(date),
          durationInDays: 1,
        },
      ];
    }

    return [
      ...intervals.slice(0, -1),
      {
        ...lastInterval,
        to: getEndOfDayDate(date),
        durationInDays: lastInterval.durationInDays + 1,
      },
    ];
  }, []);
};
