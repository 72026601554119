import { Dialog } from '@mui/material';

import { useDialogBlockScroll } from 'hooks/useDialogBlockScroll/useDialogBlockScroll';

import { PlanProjectExtensionProps } from './PlanProjectExtensionModal.types';
import { PlanProjectExtensionContent } from './planProjectExtensionContent/PlanProjectExtensionContent';

export const PlanProjectExtensionModal = ({
  open,
  onClose,
  projectId,
  projectEndDate,
  projectAssignments,
}: PlanProjectExtensionProps) => {
  useDialogBlockScroll(open);

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <PlanProjectExtensionContent
        projectId={projectId}
        projectEndDate={projectEndDate}
        projectAssignments={projectAssignments}
        onClose={onClose}
      />
    </Dialog>
  );
};
