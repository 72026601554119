import { Emoji, Tooltip } from 'lux/components';
import { EmojiType } from 'lux/components/atoms/emoji/Emoji.types';
import { Box, Stack } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { EmploymentType } from 'api/types/EmploymentType.enum';
import { formatDate, isDateBefore, isDateBetween, isFutureDate } from 'utils/dateUtils';
import { createIntervalsFromAbsences, AbsenceInterval } from 'utils/createIntervalsFromAbsences';
import { useMappedAbsences } from '../../hooks/useMappedAbsences/useMappedAbsences';
import { AssignmentType } from 'api/types/AssignmentType.enum';

import { EmojiBadgesProps } from './EmojiBadges.types';
import * as styles from './EmojiBadges.styles';

const getDigitEmoji = (n: number): EmojiType => {
  switch (n) {
    case 1:
      return 'digitOne';
    case 2:
      return 'digitTwo';
    case 3:
      return 'digitThree';
    case 4:
      return 'digitFour';
    case 5:
      return 'digitFive';
    case 6:
      return 'digitSix';
    case 7:
      return 'digitSeven';
    default:
      return 'digitOne';
  }
};

export const EmojiBadges = ({
  employmentType,
  isLeader,
  proposalCount,
  employedTo,
  absences,
  assignmentType,
  replacedAssignment,
}: EmojiBadgesProps) => {
  const { formatMessage } = useLocale();

  const absenceData = useMappedAbsences(absences);

  const absenceIntervals = absenceData?.daysOff && createIntervalsFromAbsences(absenceData?.daysOff);

  const currentlyOnLeave =
    absenceIntervals?.some((interval) => isDateBetween(new Date(), { start: interval.from, end: interval.to })) ||
    false;
  const futureLeave = absenceIntervals?.reduce<AbsenceInterval | undefined>((prev, curr) => {
    if (!prev) return curr;

    if (isFutureDate(curr.from) && isDateBefore(curr.from, prev.from) && curr.durationInDays >= 3) {
      return curr;
    }

    return prev;
  }, undefined);

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={styles.emojiBadges}>
      {employmentType === EmploymentType.LTSH && (
        <Tooltip title={formatMessage({ id: AppMessages['emojiBadge.subcontractor'] })} placement="top" arrow>
          <Box>
            <Emoji type="outsource" />
          </Box>
        </Tooltip>
      )}
      {isLeader && (
        <Tooltip title={formatMessage({ id: AppMessages['emojiBadge.leader'] })} placement="top" arrow>
          <Box>
            <Emoji type="star" />
          </Box>
        </Tooltip>
      )}
      {proposalCount > 0 && (
        <Tooltip
          title={formatMessage({ id: AppMessages['emojiBadge.assignedProjects'] }, { count: proposalCount })}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type={getDigitEmoji(proposalCount)} />
          </Box>
        </Tooltip>
      )}
      {assignmentType === AssignmentType.replacement && (
        <Tooltip
          title={formatMessage({ id: AppMessages['emojiBadge.replacement'] }, { replacedAssignment })}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="clock" />
          </Box>
        </Tooltip>
      )}
      {employedTo && (
        <Tooltip
          title={formatMessage({ id: AppMessages['emojiBadge.employedTo'] }, { employedTo })}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="printer" />
          </Box>
        </Tooltip>
      )}
      {currentlyOnLeave && (
        <Tooltip title={formatMessage({ id: AppMessages['emojiBadge.onHolidays'] })} placement="top" arrow>
          <Box>
            <Emoji type="holiday" />
          </Box>
        </Tooltip>
      )}
      {!currentlyOnLeave && futureLeave && (
        <Tooltip
          title={formatMessage(
            { id: AppMessages['emojiBadge.futureHolidays'] },
            { dayCount: futureLeave.durationInDays, startingDate: formatDate(futureLeave.from) },
          )}
          placement="top"
          arrow
        >
          <Box>
            <Emoji type="calendar" />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
