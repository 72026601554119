import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { peopleKeys } from 'utils/queryKeys';
import { getPersonAbsencesQuery } from 'api/actions/getPersonAbsences/getPersonAbsences';
import {
  GetPersonAbsencesParams,
  GetPersonAbsencesResponse,
} from 'api/actions/getPersonAbsences/getPersonAbsences.types';
import { useMappedAbsences } from '../useMappedAbsences/useMappedAbsences';

import { UsePersonAbsencesParams } from './usePersonAbsences.types';

export const usePersonAbsences = (params: UsePersonAbsencesParams) => {
  const { isAuthenticated } = useAuth();

  const { personId, ...args } = params;

  const response = useQuery<GetPersonAbsencesParams, GetPersonAbsencesResponse>(
    peopleKeys.singlePersonAbsences(personId, params.fromDate, params.toDate),
    getPersonAbsencesQuery(personId),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      args,
    },
  );

  const data = useMappedAbsences(response.data?.absences);

  return { ...response, data };
};
