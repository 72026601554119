import { MutationFn } from 'hooks/useMutation/useMutation.types';

import { EditAssignmentParams } from './editNeedActions.types';

export const editAssignmentMutation: (projectId: string, assignmentId: string) => MutationFn<EditAssignmentParams> =
  (projectId: string, assignmentId: string) => (body) => ({
    endpoint: `/projects/${projectId}/assignments/${assignmentId}`,
    method: 'PATCH',
    params: body,
  });
